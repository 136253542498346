import  smoothScroll from 'smoothscroll'; 

const show = section => {
  let destination = document.getElementById(section)
  console.log('showing '+section);
  if(destination) smoothScroll(destination)
  else {
    console.log(section + ' not found')
  }
}

const route = section => {
  let href = location.href.replace(/#[A-Za-z0-9_]*$/,'');
  href += `#${section}`;
  history.pushState({section},null,href)
  show(section);
}
if(typeof window !== 'undefined'){
  window.onpopstate = e => {
    console.log(e.state);
    if(e.state) show(e.state.section);
  }
  const hash = location.hash.substr(1);
  history.replaceState({section: hash}, null, location.href)
  setTimeout(() => show(hash), 50);
}





export { route };
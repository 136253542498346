<script>
import Brue from 'brue';
import { route } from './../router';

const whyAnswer = "Not every development environment has an easy way to log data, variables, parameters, etc. Even those that do may still make it difficult to actually comprehend large JSON blobs as a human being. Blobster is a simple window into any development process that can encode JSON and make HTTP requests."
const remoteServerAnswer = "Do you have SSH access? Not everyone is aware that SSH port forwarding can operate in both directions. Making the Blobster HTTP server available on the remote box may be as simple as "

const FAQ = Brue(() => {

  return $ => { $
    .a('div .faq')
      .b('a #FAQ')
      .b('h2').text('F.A.Q.')
      .b('div .content')
        .c('h3').text('Why?')
        .c('p').text(whyAnswer)
        .c('h3').text('How?')
        .c('p')
          .d(String, 'Viewing a JSON blob in Blobster is simply a matter of opening the application and sending a POST to ')
          .d('span .il-code').text('localhost:8108/blob')
          .d(String, '. The ')
          .d('a').text('API')
            .on('click', () => route('API'))
          .d(String, ' is quite simple.')
        .c('h3').text('How do I view blobs from a remote server?')
        .c('p')
          .d(String, remoteServerAnswer)
          .d('span .il-code').text('ssh -fNT -R 8108:localhost:8108 devbox')
          .d(String, ' with OpenSSH.')
        .c('h3').text('How do I send blobs from within a docker container?')
        .c('p')
          .d(String, "Making Blobster's server available from within the docker network isn't as easy. Alternatively, use ")
          .d('a', {href: 'https://github.com/ennisj9/blobster-relay'}).text('blobster-relay')
          .d(String, " to extract blobs out of the docker network. Blobster will automatically establish a websocket connection with the relay on port ")
          .d('span .il-code').text('8109')
          .d(String, '.')
  }

})
export default FAQ;
</script>

<style lang="postcss">
.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  max-width: 750px;
  margin: 20px;
  margin-top: -10px;
  display: block;
  text-align: left;
}
.content a {
  text-decoration: underline;
  cursor: pointer
}
.content h3 {
  margin-bottom: -5px;
}
.il-code {
  background: #EEE;
  font-size: 14px;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-family: 'Roboto Mono';
  white-space: nowrap;
}
</style>
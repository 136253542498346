<script>
import Brue from 'brue';

const BlobsterLogo = Brue(() => {

  return $ => { $
    .a('div .blobster-logo')
      .b('img', {src: require('./../assets/claw.png')})
      .b('h1').text('Blobster')
      .b('div .beta').text('beta')
  }

})
export default BlobsterLogo;
</script>

<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300&display=swap');
.blobster-logo {
  text-transform: lowercase;
  font-family: 'Fira Sans';
  display: flex;
  align-items: center;
  position: relative;
}
.blobster-logo img {
  height: 90px;
  transform: scaleX(-1);
}
.blobster-logo h1 {
  font-size: 54px;
  font-weight: 300;
  letter-spacing: -1px;
  margin-right: 15px;
}
.beta {
  position: absolute;
  top: 95px;
  left: 240px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  text-transform: uppercase;
  opacity: .6;
}

</style>
<script>
import Brue from 'brue';
import browserExample from '!raw-loader!./../assets/client_examples/browserExample.txt';
import nodeExample from '!raw-loader!./../assets/client_examples/nodeExample.txt';
import pyExample from '!raw-loader!./../assets/client_examples/pythonExample.txt';
import phpExample from '!raw-loader!./../assets/client_examples/phpExample.txt';
import { onMounted } from 'vue';
import Prism from 'prismjs';

import 'prismjs/components/prism-markup-templating.js';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-php.js';

console.log(phpExample)


const Clients = Brue(() => {

  onMounted(Prism.highlightAll)

  return $ => { $
    .a('div .clients')
      .b('a #Clients')
      .b('h2').text('Clients')
      .b('p').text('Here are some dependency free clients to include in your project to get you started.')
      .b('div .example')
        .c('h4').text('Browser')
        .c('pre')
          .d('code .language-javascript').text(browserExample)
      .b('div .example')
        .c('h4').text('Node')
        .c('pre')
          .d('code .language-javascript').text(nodeExample)
      .b('div .example')
        .c('h4').text('Python')
        .c('pre')
          .d('code .language-python').text(pyExample)
      .b('div .example')
        .c('h4').text('PHP')
        .c('pre')
          .d('code .language-php').text(phpExample)
  }

})
export default Clients;
</script>

<style lang="postcss">
.clients {
  background: #F3F3F3;  
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clients p {
  text-align: left;
  width: 100%;
  max-width: 750px;
}
.example {
  text-align: left;
  max-width: 750px;
  width: 100%;
  font-size: 14px;
}
.example h4 {
  margin-bottom: 10px;
  margin-top: 16px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
}
.example pre {
  margin: 0;
}
</style>
<script>
import Brue from 'brue';
import { route } from './../router';

const SiteNavigation = Brue(() => {

  const items = ['Download','FAQ','API','Clients'];

  return $ => { $
    .a('div .site-navigation')
      items.forEach(item => { $
        .b('div .nav-item').text(item)
          .on('click', () => route(item))
      });
    console.log('site nav render'); 
  }

})
export default SiteNavigation;
</script>

<style lang="postcss">

.site-navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.nav-item {
  margin: 10px 20px 10px 20px;
  padding: 10px 25px 10px 25px;
  font-family: 'Roboto';
  font-weight: 500;
  border-bottom: 3px solid #CCC;
  text-transform: uppercase;
  cursor: pointer;
}
.nav-item:hover {
  background:#F3F3F3;
  border-bottom: 3px solid #333;
}
</style>
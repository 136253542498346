<script>
import Brue from 'brue';

const DownloadChoice = Brue('type','version', props => {

  let extension = {
    'Mac': 'dmg',
    'Windows': '.exe'
  }[props.type]

  let filename = `blobster-${props.version}.${extension}`;

  return $ => { $
    .a('a .download-choice', {
      href: `https://file.blobster.app/${filename}`,
      download: filename
      })
      .b('div .left')
        .c('img .icon', {
          src: require(`./../assets/${props.type.toLowerCase()}.svg`)
        })
      .b('div .middle')
        .c('div .label').text(`for ${props.type}`)
        .c('div .version').text('v'+props.version)
      .b('div .right')
        .c('i .icon .material-icons').text('download')
  }

})
export default DownloadChoice;
</script>

<style lang="postcss">
.download-choice {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #FFF;
  margin: 20px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.download-choice:hover {
  background: #DDD;
}
.label {
  font-size: 20px;
  font-family: 'Open Sans';
  font-weight: 400;
  text-align: left;
}
.icon {
  width: 35px;
  margin: 17px;
}
.version {
  text-align: left;
  font-size: 13px;
  color: #999;
  margin-top: 2px
}
.left {
  flex: 0;
}
.right {
  flex-grow: 0;
}
.middle {
  margin-left: 5px;
  flex-grow: 1;
  width: 180px;
}
</style>
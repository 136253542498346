<script>
import Brue from 'brue';
import DownloadChoice from './DownloadChoice.vue';

const Download = Brue(() => {

  return $ => { $
    .a('div .download')
      .b('a #Download')
      .b('h2').text('Download')
      .b('div .choices')
        .c(DownloadChoice, {type: 'Windows', version: '0.9.0'})
        .c(DownloadChoice, {type: 'Mac', version: '0.9.0'})
  }

})
export default Download;
</script>

<style lang="postcss">
.download {
  background: #F3F3F3;  
  padding-bottom: 30px;
}
.choices {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  overflow: hidden;
}
</style>
<script>
import Brue from 'brue';
import BlobsterLogo from './components/BlobsterLogo.vue'
import MainPromo from './components/MainPromo.vue';
import SiteNavigation from './components/SiteNavigation.vue';
import Download from './components/Download.vue';
import FAQ from './components/FAQ.vue';
import Clients from './components/Clients.vue';

const App = Brue(() => { 
  return $ => { $
    .a('div #top')
      .b(BlobsterLogo)
    .a(SiteNavigation)
    .a(MainPromo)
    .a(Download)
    .a(FAQ)
    .a(Clients)
  }
});

export default App;
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@300;400;500&family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body, html {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
#top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EEE;
  height: 210px;
}
.copy {
  font-family: "Open Sans";
  font-size: 15px;
}
h2 {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 20px;
}
h3 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  margin-top: 35px;
  margin-bottom: 0px;
}
p {  
  font-family: 'Open Sans';
  line-height: 1.7;
  font-size: 15px;
}
</style>

<script>
import Brue from 'brue';

const MainPromo = Brue(() => {

  return $ => { $
    .a('div .main-promo')
      .b('img .screen-shot', {src: require('./../assets/blobster_screenshot.png')})
      .b('div .promo-content')
        .c('h2').html('Develop with <br />transparency')
        .c('ul .feature-list .copy')
          .d('li').text('Receive JSON blobs as a local HTTP server')
          .d('li').text('POST to blobster during development')
          .d('li').text('View JSON with a human readable interface')
          .d('li').text('Debug transient data on the fly')
  }

})
export default MainPromo;
</script>

<style lang="postcss">
.main-promo {
  font-family: Roboto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
  margin-bottom: 40px;
}
.screen-shot {
  width: 464px;
  height: auto;
  overflow: hidden;
  image-rendering: -moz-crisp-edges;
  image-rendering:   -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  margin: 10px;
  filter: drop-shadow(2px 2px 5px rgba(0,0,0,.15)) 
}
.promo-content {
  display: block;
  text-align: left;
  margin: 40px;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.promo-content h2 {
  margin: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.feature-list {
  flex-grow: 3;
  padding: 0;
  margin-left: 20px;
  margin-top: 0px;
}
.feature-list li {
  margin-top: 15px;
  max-width: 300px;
}
</style>